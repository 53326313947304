import logo from './logo.svg';
import './App.css';
import bakim from './images/bakim.png'

function App() {
  return (
    <div className="App">
      <img style={{marginTop: '2rem'}} src={bakim} alt='Bakim'/>
      <h2>Çok Yakında Hizmetinizdeyiz..</h2>
    </div>
  );
}

export default App;
